.message-content.highlight-snippets {
    ::selection {
        background-color: var(--snippet-color-selecting);
    }
    snippet,
    .snippet,
    .snippet-suggestion {
        display: inline;
    }
    // .snippet-suggestion {
    //     background-color: var(--snippet-suggestion-color);
    //     &:not(.snippet) {
    //         &:hover {
    //             background-color: var(--snippet-suggestion-hover);
    //         }
    //     }
    // }
    .snippet-suggestion {
        box-shadow: inset 0px -2px 0px 0px var(--snippet-suggestion-hover);
    }
    &:not(.under-snippet-selection) {
        .snippet-suggestion {
            &:not(:has(.snippet)):not(.snippet) {
                &:hover {
                    background-color: var(--snippet-suggestion-color);
                }
            }
        }
    }
    snippet,
    .snippet {
        cursor: pointer;
        background-color: var(--snippet-color);
        &.hover {
            background-color: var(--snippet-hover);
        }
    }
}
