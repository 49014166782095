.view-message-holder {
    display: flex;
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    min-width: 1px;
    background: var(--main-background);
    --view-message-holder-left-padding: 56px;

    .pageMessageCenterContent {
        max-width: 1400px;
        margin: 0 auto;
        min-width: 400px;
    }

    .view-message-holder-topbar {
        display: flex;
        height: 56px;
        min-height: 56px;
        padding: 0px 16px;
        border-bottom: solid 1px var(--border-color);
        .separator {
            height: 24px;
            width: 1px;
            // background: var(--border-color);
            display: block;
            margin: 0px 8px;
        }
    }

    .below-message-topbar {
        padding-bottom: 68px;
        padding-right: 32px;
        overflow-y: auto;
        flex: 1 0 0;
        .below-message-topbar-topspace {
            width: 100%;
            min-height: 16px;
            display: block;
        }
    }

    .message-viewer {
        left: 0;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        background: var(--main-background);
        min-height: 130px;
        position: relative;
        display: flex;
        flex: 1;
        padding: 0px;

        .message-view-holder-titleline {
            display: grid;
            padding-left: 16px;
            grid-template-columns: 32px 1fr auto;
            grid-template-rows: auto;
            grid-template-areas: "channel title actions";
            grid-column-gap: 8px;
            .message-channel {
                grid-area: channel;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 22px;
                line-height: 22px;
                height: 28px;
            }
            .title {
                grid-area: title;
                display: inline-flex;
                width: 100%;
                .text-header-first-line {
                    font-size: 20px;
                    overflow: hidden; // height: 30px;
                    flex: 1;
                    width: 100%;
                    .message-title-input {
                        &:not(.width-fit-content) {
                            width: 100%;
                        }
                        line-height: 28px;
                        border: none;
                        outline: 0px solid transparent;
                        display: inline-block;
                        font-size: 22px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                    }
                }
            }
            .actions {
                grid-area: actions;
            }
        }
        .mess-content-header {
            padding-left: var(--view-message-holder-left-padding);
            padding-top: 8px;
            padding-bottom: 8px;
            user-select: text;
            position: relative;
            display: grid;
            align-items: center;
            grid-template-columns: 1fr max-content;
            grid-template-rows: repeat(2, max-content);
            grid-template-areas: "sender date" "tags tags";
            grid-row-gap: 16px;
            grid-column-gap: 8px;
            .date {
                grid-area: date;
                font-size: 13px;
                line-height: 24px;
                height: 100%;
            }
            .sender {
                grid-area: sender;
                flex-wrap: wrap;
                flex-flow: row wrap;
                align-content: flex-end;
            }
            .tags {
                grid-area: tags;
            }
        }
        .holder-text-content {
            padding-left: var(--view-message-holder-left-padding);
            padding-top: 8px;
            padding-bottom: 16px;
            position: relative;
            display: flex;
            flex: 1;
            &.hidden-content {
                display: none;
                user-select: none;
            }
        }
    }

    .message-part-holder {
        padding-left: var(--view-message-holder-left-padding);
        padding-top: 20px;
        display: block;
    }

    .message-viewer-footer {
        overflow-y: auto;
        padding-left: var(--view-message-holder-left-padding);
        padding-right: 32px;
        .message-comment-box {
            padding-right: 50px; // ? for Intercom bullet
            display: block;
            .send-external {
                height: fit-content;
                margin-left: 40px;
                font-size: 13px;
                width: fit-content;
                padding: 0px 0px 6px 0px;
                margin-bottom: 2px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
}

.message-content {
    display: inline-block;
    ::-moz-selection,
    ::selection,
    .selected {
        background-color: var(--snippet-color) !important;
    }
    &.chunkable {
        padding-left: 18px;
        margin-left: -18px;
        padding-right: 18px;
        margin-right: -18px;
    }
}

.conversation-message {
    // max-width: 100%; -> causes: https://app.clubhouse.io/harvestrio/story/5043/when-chunking-bold-content-in-note-chunk-is-displayed-with-line-breaks-after-each-letter
    display: block;
    width: fit-content;
    --bubble-horizontal-padding: 16px;
    // margin-left: calc(-1 * var(--bubble-horizontal-padding));
    padding: 8px var(--bubble-horizontal-padding);
    border-radius: 12px;
    user-select: text;
    &:not(.full-link) {
        background: var(--surface-color);
    }
    &.conversation-note {
        background: #ffe3b5;
    }
    & + .conversation-message {
        margin-top: 8px;
    }
}

#snippet {
    cursor: pointer;
    &:hover {
        color: var(--search-highlight);
    }
}

.slack_code {
    background: #fbfbfb;
    padding: 8px;
    border-radius: 3px;
    border: solid 1px var(--border-color);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.slack_bold {
    font-weight: 500;
}

.slack_strikethrough {
    text-decoration: line-through;
}

.slack_italics {
    font-style: italic;
}

.slack_block {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 16px;
    border-left: solid 4px var(--surface-color);
}

.card-discovery-snippet-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-left: 4px;
    margin-bottom: 8px;

    .actions-container {
        visibility: hidden;
        position: absolute;
        right: 0px;
        top: -4px;
        background: rgb(255 255 255 / 75%);
        border: solid 1px var(--border-color);
        padding: 4px;
        border-radius: 3px;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .snippet-content-suggested,
    .snippet-content-persisted {
        text-align: left;
        padding: 0px 8px 0px 8px;
        width: 100%;
        border-left: 2px solid transparent;
    }

    .snippet-content-suggested {
        border-left-color: #c890ff;
    }
    .snippet-content-persisted {
        border-left-color: var(--border-color);

        --lines-ellipsis-number: 4;
        -webkit-line-clamp: var(--lines-ellipsis-number);
        line-clamp: var(--lines-ellipsis-number);
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
    }

    &.multi-content {
        &:hover {
            .snippet-content-suggested {
                background: var(--purple-10);
            }
            .actions-container {
                visibility: visible;
            }
        }
        .actions-container:hover + .suggested-content,
        .suggested-content:hover {
            background: #f8f8f8;
        }
    }
}
